@import 'Styles/includes';

/**
*
* Hero
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Hero {
    $root: &;

    &__Image {
        height: 200px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-color: $gray-5;

        @include media(md) {
            height: 430px;
        }
    }

    &__Content {
        padding: 32px 15px 32px 15px;
        background-color: white;
        text-align: center;
        border-radius: 4px;
        transform: translateY(-20%);
        box-shadow: 0 0 8px 0 rgba(black, 0.15);
        max-width: calc(100% - 30px);
        margin: 0 auto;

        @include media(md) {
            padding: 40px 15px 56px 15px;
        }

        @include media(xl) {
            max-width: maxwidth(article);
            transform: translateY(-50%);
            padding: 40px 15px 56px 15px;
            margin-bottom: -112px;
        }
    }

    &__Icon {
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 88px;
        width: auto;
        margin-bottom: 16px;
    }

    &__Title {
        line-height: 1.25;
        font-size: 2.6rem;
        margin-bottom: 8px;
        font-weight: bold;

        @include media(md) {
            font-size: 4.4rem;
            margin-bottom: 16px;
            width: 780px;
            margin: 0 auto 16px auto;
        }
    }

    &__Preamble {
        font-size: 1.6rem;
        line-height: 1.5;

        @include media(md) {
            max-width: 644px;
            font-size: 1.8rem;
            margin: 0 auto;
        }
    }
}