@import 'Styles/includes';

/**
*
* Breadcrumbs
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Breadcrumbs {
    $root: &;

    position: relative;
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;

    &__Nav {
        @include u-wrap(article);

        position: relative;
    }

    &__Item {
        display: inline-block;
    }

    &__Link {
        text-decoration: none;
        letter-spacing: 0.33px;
        font-size: 1.2rem;
        color: $text;
        position: relative;

        &--Small {
            display: block;
            @include media(sm) {
                display: none;
            }
        }

        &--Large {
            display: none;
            @include media(sm) {
                display: block;
            }
        }

        &:after {
            content: '/';
            margin: 0 8px;
        }

        #{$root}__Item--Last & {
            &:after {
                display: none;
            }
        }
    }
}
