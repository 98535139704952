@import 'Styles/includes';

/**
*
* EmbedMessage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EmbedMessage {
    $root: &;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__Bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background: white !important;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $embed-message-bg !important;
            opacity: .1;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 8px;
        color: black;
        font-size: 1.2rem;
        line-height: 1.4;
        text-align: center;

        @include media(xs) {
            padding: 20px;
            font-size: 1.4rem;
        }

        @include media(sm) {
            font-size: 1.8rem;
        }
    }

    &__Title {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: .2px;
        color: $text;
        margin-bottom: 8px;

        @include media(xs) {
            font-size: 1.8rem;
        }

        @include media(sm) {
            font-size: 2rem;
        }
    }

    &__Text {
        max-width: 550px;
        margin: 0 0 8px;
        font-size: 1.2rem;

        a {
            color: $link-color !important;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        @include media(xs) {
            font-size: 1.4rem;
        }

        @include media(sm) {
            font-size: 1.6rem;
            margin-bottom: 16px;
        }
    }

    &__Link {
        margin-top: 16px;
        color: $link-color !important;
        font-size: 1.6rem;
        text-decoration: none;
        border-bottom: 1px solid transparent;
        transition: border-color .3s ease-in-out;

        &:hover {
            border-color: $link-color !important;
            text-decoration: none !important;
        }
    }
}
