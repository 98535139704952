@import 'Styles/includes';

/**
*
* StreamField
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.StreamField {
    $root: &;

    &__Components {
        overflow: hidden;
    }

    &__Loader {
        display: flex;
        justify-content: center;
        padding: 24px 0;
    }

    &__Component {
        @include u-wrap(article);

        padding: 0 15px;
        margin-top: 48px;
        margin-bottom: 48px;

        @include media(md) {
            margin-top: 64px;
            margin-bottom: 64px;
        }

        @include media(lg) {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        @include media(xl) {
            padding-left: 0;
            padding-right: 0;
        }

        &--Hidden {
            display: none;
        }

        &--Visible {
            &:global {
                animation: slideDownFadeInLight 0.5s ease forwards;
            }
        }

        &--FullWidth {
            max-width: 100%;
        }

        &--Embedded {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }

        // Remove bottom margin so they can be displayed directly after each other
        &--NoSpacing {
            margin-bottom: 0;

            // Remove margin from first item
            &:first-of-type {
                margin-top: 0;
            }

            // Remove top margin so they can be displayed directly after each other
            #{$root}__Component--NoSpacing + & {
                margin-top: 0;
            }
        }

        &--CardResourceList,
        &--ArrangementEntryList,
        &--CardNewsList,
        &--CardEditorialList,
        &--CardMethodList {
            margin-bottom: 64px;

            @include media(md) {
                margin-bottom: 80px;
            }

            @include media(xl) {
                margin-bottom: 100px;
            }
        }

        // Remove bottom margin since it is handled by next component instead
        &--Title {
            margin-bottom: 0;
        }

        // Any component after Title should have a lower margin, since noMargin is always added to Title in StreamField
        &--Title + &:not(#{$root}__Component--Title) {
            margin-top: 48px !important;
        }

        // Remove bottom margin so they can be displayed directly after each other
        &--CardDownload {
            margin-bottom: 0;

            // Fix double border issue and spacing after each other
            #{$root}__Component--CardDownload + & {
                margin-top: -1px !important;
            }
        }

        &--Wysiwyg {
            width: 100%;

            @include media(md) {
                padding: 0;
                max-width: 756px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}