@import 'Styles/includes';

/**
*
* Wysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Wysiwyg {
    $root: &;

    word-wrap: break-word;

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    h2,
    h3,
    h4 {
        font-weight: 700;
        margin: 16px 0 8px 0;
        line-height: 1.5;
    }

    h2 {
        font-size: 2.2rem;
        @include media(md) {
            font-size: 2.4rem;
        }
    }

    h3 {
        font-size: 1.8rem;
        @include media(md) {
            font-size: 2.0rem;
        }
    }

    h4 {
        font-size: 1.8rem;
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    p {
        margin-bottom: 15px;
        line-height: 1.6;

        @include media(lg) {
            font-size: 1.8rem;
        }
    }

    ul,
    ol {
        margin: 15px 0;
        padding-left: 30px;
        line-height: 1.6;

        @include media(lg) {
            font-size: 1.8rem;
        }

        ul,
        ol {
            margin-top: 0;
            margin-left: 10px;
        }

        li {
            margin: 10px 0;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    li {
        line-height: 1.6;
    }

    hr {
        border: none;
        height: 1px;
        color: $gray-20;
        background-color: $gray-20;
    }

    img {
        height: auto;
        max-width: 100%;
        margin: 15px 0 15px 0;

        &:global {
            &.richtext-image {
                &--left {
                    @include media(md) {
                        float: left;
                        margin: 0 24px 16px 0;
                    }
                }

                &--right {
                    @include media(md) {
                        float: right;
                        margin: 0 0 16px 24px;
                    }
                }
            }
        }
    }

    figure {
        figcaption {
            margin-top: 2px;
            margin-bottom: 24px;
            font-size: 1.4rem;
        }
    }

    :global {
        .figure {
            // Set max widths so caption is the same width as image
            &.richtext-image--left,
            &.richtext-image--right {
                max-width: 500px;
            }

            &.richtext-image--thumbnail {
                max-width: 200px;
            }

            &--richtext-image {
                margin: 15px 0 15px 0;

                img {
                    margin: 0;
                }

                &.richtext-image {
                    &--left {
                        img {
                            float: none;
                            margin: 0;
                        }

                        &.richtext-image--thumbnail {
                            float: left;
                            margin: 0 16px 8px 0;
                        }

                        @include media(md) {
                            float: left;
                            margin: 0 24px 16px 0;
                        }

                        figcaption {
                            margin-bottom: 0;
                        }
                    }

                    &--right {
                        img {
                            float: none;
                            margin: 0;
                        }

                        &.richtext-image--thumbnail {
                            float: right;
                            margin: 0 0 8px 16px;
                        }

                        @include media(md) {
                            float: right;
                            margin: 0 0 16px 24px;
                        }

                        figcaption {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    a {
        color: $link-color;
        text-decoration: none;
        display: inline-flex;
        align-items: baseline;
        line-height: 1.25;
        text-decoration: none;
        position: relative;

        @include href-external-theme() {
            &::after {
                content: '';
                width: 12px;
                height: 12px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: bottom right;
                margin-left: 6px;
            }
        }

        @include href-external-theme-icon();

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;
        }
    }

    blockquote {
        position: relative;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: normal;
        padding-left: 56px;
        margin: 24px 0 24px;
        color: $quote-color;
        z-index: 0;

        @include media(md) {
            font-size: 2.0rem;
        }

        @include media(lg) {
            padding-left: 0;
            margin: 40px 20px 40px 85px;
            font-size: 2.2rem;
            line-height: 1.5;
        }

        &::after {
            content: '\201D';
            font-weight: normal;
            position: absolute;
            font-size: 12rem;
            left: 0px;
            top: -40px;
            z-index: -1;

            @include media(lg) {
                color: $quote-color;
                top: -70px;
                left: -92px;
                font-size: 20rem;
                z-index: 1;
            }
        }
    }

    &--Small {
        p, ul {
            font-size: 1.6rem;

            @include media(lg) {
                font-size: 1.6rem;
            }
        }
    }
}
